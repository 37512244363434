import './App.css';
import EmailForm from './EmailForm';

function App() {
  return (

    <div id="container">
      <h1>From the Kitchen</h1>

      <h2>Join our mailing list</h2>
      <EmailForm />
    </div>

  );
}

export default App;
